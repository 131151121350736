import { SupabaseClient } from '@supabase/supabase-js'
import React, { useEffect, useRef, useState } from 'react'
import {
  I18nVariables,
  RedirectTo,
  ViewSignUp,
  ViewSignIn,
  VIEWS,
  ViewType,
} from '@supabase/auth-ui-shared'
import { Appearance } from './../../../types'
import {
  Anchor,
  Button,
  Container,
  Input,
  Label,
  Message,
} from './../../UI/index'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { black_list } from '../blacklist_emails'


export interface EmailAuthProps {
  authView?: ViewSignIn | ViewSignUp
  defaultEmail?: string
  defaultPassword?: string
  setAuthView?: any
  setDefaultEmail?: (email: string) => void
  setDefaultPassword?: (password: string) => void
  supabaseClient: SupabaseClient
  showLinks?: boolean
  redirectTo?: RedirectTo
  additionalData?: { [key: string]: any }
  magicLink?: boolean
  i18n?: I18nVariables
  appearance?: Appearance
  children?: React.ReactNode,
  setMessage?: (options: { isError: boolean, message: string } | null) => void
}

function EmailAuth({
  authView = 'sign_in',
  defaultEmail = '',
  defaultPassword = '',
  setAuthView = () => {},
  setDefaultEmail = (email) => {},
  setDefaultPassword = (password) => {},
  supabaseClient,
  showLinks = false,
  redirectTo,
  additionalData,
  magicLink,
  i18n,
  appearance,
  children,
  setMessage
}: EmailAuthProps) {
  const isMounted = useRef<boolean>(true)
  const [email, setEmail] = useState(defaultEmail)
  const [password, setPassword] = useState(defaultPassword)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMsg] = useState('')
  const [captchaToken, setCaptchaToken] = useState<string>("");


  useEffect(() => {
    isMounted.current = true
    setEmail(defaultEmail)
    setPassword(defaultPassword)

    return () => {
      isMounted.current = false
    }
  }, [authView])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setMessage ? setMessage(null) : setError('')
    setLoading(true)
    const email_domain = email.split('@')[1];
    if(black_list.includes(email_domain)){
      setMessage ? setMessage({ isError: true, message: 'Email domain is not allowed' }) : setError("Email domain is not allowed")
      return;
    }
    switch (authView) {
      case 'sign_in':

      // check email domain blacklist first
      

        const { error: signInError } =
          await supabaseClient.auth.signInWithPassword({
            email,
            password,
            options:{
              captchaToken: captchaToken
            }
          })
        if (signInError) {
          setMessage? setMessage({ isError: true, message: signInError.message }) : setError(signInError.message)
        }
        break
      case 'sign_up':
        let options: { emailRedirectTo: RedirectTo; data?: object } = {
          emailRedirectTo: redirectTo,
        }
        // if (additionalData) {
          // options.data = additionalData
        // }
        const {
          data: { user: signUpUser, session: signUpSession },
          error: signUpError,
        } = await supabaseClient.auth.signUp({
          email,
          password,
          options:
          {
            //@ts-ignore
            captchaToken: captchaToken
          },
        })
        if (signUpError) {
          setMessage? setMessage({ isError: true, message: signUpError.message }) : setError(signUpError.message)
        }
        // Check if session is null -> email confirmation setting is turned on
        else if (signUpUser && !signUpSession)
          setMessage ? setMessage({ isError: false, message: i18n?.sign_up?.confirmation_text as string }) : setMsg(i18n?.sign_up?.confirmation_text as string)
        break
    }

    /*
     * it is possible the auth component may have been unmounted at this point
     * check if component is mounted before setting a useState
     */
    if (isMounted.current) setLoading(false)
  }

  const handleViewChange = (newView: ViewType) => {
    setDefaultEmail(email)
    setDefaultPassword(password)
    setAuthView(newView)
  }

  const labels = i18n?.[authView]

  return (
    <form
      id={authView === 'sign_in' ? `auth-sign-in` : `auth-sign-up`}
      onSubmit={handleSubmit}
      autoComplete={'on'}
      style={{ width: '100%' }}
    >
      <Container direction="vertical" gap="large" appearance={appearance}>
        <Container direction="vertical" gap="large" appearance={appearance}>
          <div>
            <Label htmlFor="email" appearance={appearance}>
              {labels?.email_label}
            </Label>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder={labels?.email_input_placeholder}
              defaultValue={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              autoComplete="email"
              appearance={appearance}
            />
          </div>
          <div>
            <Label htmlFor="password" appearance={appearance}>
              {labels?.password_label}
            </Label>
            <Input
              id="password"
              type="password"
              name="password"
              placeholder={labels?.password_input_placeholder}
              defaultValue={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              autoComplete={
                authView === 'sign_in' ? 'current-password' : 'new-password'
              }
              appearance={appearance}
            />
          </div>
          {children}
        </Container>
        <HCaptcha
          sitekey="41dabfed-e123-4f38-b60c-23d40088514a"
          onVerify={(t)=>{
            setCaptchaToken(t)
          }}
        />
        <Button
          type="submit"
          color="primary"
          loading={loading}
          appearance={appearance}
        >
          {loading ? labels?.loading_button_label : labels?.button_label}
        </Button>

        {showLinks && (
          <Container direction="vertical" gap="small" appearance={appearance}>
            {authView === VIEWS.SIGN_IN && magicLink && (
              <Anchor
                href="#auth-magic-link"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault()
                  setAuthView(VIEWS.MAGIC_LINK)
                }}
                appearance={appearance}
              >
                {i18n?.magic_link?.link_text}
              </Anchor>
            )}
            {authView === VIEWS.SIGN_IN && (
              <Anchor
                href="#auth-forgot-password"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault()
                  setAuthView(VIEWS.FORGOTTEN_PASSWORD)
                }}
                appearance={appearance}
              >
                {i18n?.forgotten_password?.link_text}
              </Anchor>
            )}
            {authView === VIEWS.SIGN_IN ? (
              <Anchor
                href="#auth-sign-up"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault()
                  handleViewChange(VIEWS.SIGN_UP)
                }}
                appearance={appearance}
              >
                {i18n?.sign_up?.link_text}
              </Anchor>
            ) : (
              <Anchor
                href="#auth-sign-in"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault()
                  handleViewChange(VIEWS.SIGN_IN)
                }}
                appearance={appearance}
              >
                {i18n?.sign_in?.link_text}
              </Anchor>
            )}
          </Container>
        )}
      </Container>
      {message && <Message appearance={appearance}>{message}</Message>}
      {error && (
        <Message color="danger" appearance={appearance}>
          {error}
        </Message>
      )}
    </form>
  )
}

export { EmailAuth }
