import { createStitches, createTheme } from '@stitches/core';
import { I18nVariables, merge, VIEWS, en } from '@supabase/auth-ui-shared';
import React, { useEffect, useState } from 'react';
import { Auth as AuthProps } from '../../types';
import {
  EmailAuth,
  EmailAuthProps,
  ForgottenPassword,
  MagicLink,
  SocialAuth,
  UpdatePassword,
  VerifyOtp
} from './interfaces';
import { UserContextProvider, useUser } from './UserContext';
import { toast as sonnerToast } from 'sonner';

function Auth({
  supabaseClient,
  socialLayout = 'vertical',
  providers,
  providerScopes,
  queryParams,
  view = 'sign_in',
  redirectTo,
  onlyThirdPartyProviders = false,
  magicLink = false,
  showLinks = true,
  appearance,
  theme = 'default',
  localization = { variables: {} },
  otpType = 'email',
  additionalData,
  children,
  setMessage
}: AuthProps): JSX.Element | null {
  /**
   * Localization support
   */

  const i18n: I18nVariables = merge(en, localization.variables ?? {});

  const [authView, setAuthView] = useState(view);
  // get default email from additionalData

  const [defaultEmail, setDefaultEmail] = useState('');
  useEffect(() => {
    if (additionalData && additionalData.email) {
      setDefaultEmail(additionalData.email);
    }
  }, [additionalData]);
  const [defaultPassword, setDefaultPassword] = useState('');
  const [message, setMsg] = useState<{
    isError: boolean;
    message: string;
  } | null>(null);
  console.log(authView);

  function setMessageAndToast(
    message: { isError: boolean; message: string } | null
  ) {
    if (setMsg) {
      setMsg(message);
      if (message?.message){
        if (message.isError) {
          sonnerToast.error(message?.message);
        }else{
      sonnerToast(message?.message, {
        action: {
          label: 'Ok',
          onClick: () => {},
        },
      });
        }
      }
    }
  }

  /**
   * Simple boolean to detect if authView 'sign_in' or 'sign_up' or 'magic_link' is used
   *
   * @returns boolean
   */
  const SignView =
    authView === 'sign_in' ||
    authView === 'sign_up' ||
    authView === 'magic_link';

  useEffect(() => {
    createStitches({
      theme: merge(
        appearance?.theme?.default ?? {},
        appearance?.variables?.default ?? {}
      )
    });
  }, [appearance]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [authView]);

  /**
   * Wraps around all auth components
   * renders the social auth providers if SignView is true
   *
   * also handles the theme override
   *
   * @param children
   * @returns React.ReactNode
   */
  const Container = ({ children }: { children: React.ReactNode }) => (
    // @ts-ignore
    <div
      className={
        theme !== 'default'
          ? createTheme(
              merge(
                // @ts-ignore
                appearance?.theme[theme],
                appearance?.variables?.[theme] ?? {}
              )
            )
          : ''
      }
    >
      {(authView == 'sign_in' || authView == 'sign_up') && (
        <div className="">
          <div className="flex flex-col space-y-4">
            <h1 className="text-2xl font-semibold">
              {authView == 'sign_in' ? 'Sign in' : 'Sign up'}
            </h1>
            <p className="text-sm text-gray-500">
              {authView == 'sign_in'
                ? 'Sign in to access the dashboard'
                : 'Sign up to start using Mendable'}
            </p>
          </div>
        </div>
      )}
      {!!message && (
        <div
          className={`mt-2 border-t-4 rounded-b px-4 py-3 shadow-md ${
            message.isError
              ? 'bg-red-100 border-red-500 text-red-900'
              : 'bg-yellow-100 border-yellow-500 text-yellow-900'
          }`}
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className={`fill-current h-6 w-6 ${
                  message.isError ? 'text-red-500' : 'text-yellow-500'
                } mr-4`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div className="self-center">
              <p className="text-sm">{message.message}</p>
            </div>
          </div>
        </div>
      )}
      {SignView && (
        <SocialAuth
          appearance={appearance}
          supabaseClient={supabaseClient}
          providers={providers}
          providerScopes={providerScopes}
          queryParams={queryParams}
          socialLayout={socialLayout}
          redirectTo={redirectTo}
          onlyThirdPartyProviders={onlyThirdPartyProviders}
          i18n={i18n}
          view={authView}
          setMessage={(
            message: { isError: boolean; message: string } | null
          ) => {
            setMessageAndToast(message);
          }}
        />
      )}
      {!onlyThirdPartyProviders && children}
    </div>
  );

  useEffect(() => {
    /**
     * Overrides the authview if it is changed externally
     */
    const { data: authListener } = supabaseClient.auth.onAuthStateChange(
      (event) => {
        if (event === 'PASSWORD_RECOVERY') {
          setAuthView('update_password');
        } else if (event === 'USER_UPDATED') {
          setAuthView('sign_in');
        }
      }
    );
    setAuthView(view);

    return () => authListener.subscription.unsubscribe();
  }, [view]);

  const emailProp: Omit<EmailAuthProps, 'authView' | 'id'> = {
    supabaseClient,
    setAuthView,
    defaultEmail,
    defaultPassword,
    setDefaultEmail,
    setDefaultPassword,
    redirectTo,
    magicLink,
    showLinks,
    i18n,
    appearance,
    setMessage
  };

  /**
   * View handler, displays the correct Auth view
   * all views are wrapped in <Container/>
   */
  switch (authView) {
    case VIEWS.SIGN_IN:
      return (
        <Container>
          <EmailAuth
            {...emailProp}
            authView={'sign_in'}
            setMessage={(
              message: { isError: boolean; message: string } | null
            ) => {
              setMessageAndToast(message);
            }}
          />
        </Container>
      );
    case VIEWS.SIGN_UP:
      return (
        <Container>
          <EmailAuth
            appearance={appearance}
            supabaseClient={supabaseClient}
            authView={'sign_up'}
            setAuthView={setAuthView}
            defaultEmail={defaultEmail}
            defaultPassword={defaultPassword}
            setDefaultEmail={setDefaultEmail}
            setDefaultPassword={setDefaultPassword}
            redirectTo={redirectTo}
            magicLink={magicLink}
            showLinks={showLinks}
            i18n={i18n}
            additionalData={additionalData}
            setMessage={(
              message: { isError: boolean; message: string } | null
            ) => {
              setMessageAndToast(message);
            }}
            children={children}
          />
        </Container>
      );
    case VIEWS.FORGOTTEN_PASSWORD:
      return (
        <ForgottenPassword
          appearance={appearance}
          supabaseClient={supabaseClient}
          setAuthView={setAuthView}
          redirectTo={redirectTo}
          showLinks={showLinks}
          setMessage={(
            message: { isError: boolean; message: string } | null
          ) => {
            setMessageAndToast(message);
          }}
          i18n={i18n}
        />
      );

    case VIEWS.MAGIC_LINK:
      return (
        <Container>
          <MagicLink
            appearance={appearance}
            supabaseClient={supabaseClient}
            setAuthView={setAuthView}
            redirectTo={redirectTo}
            showLinks={showLinks}
            setMessage={(
              message: { isError: boolean; message: string } | null
            ) => {
              setMessageAndToast(message);
            }}
            i18n={i18n}
          />
        </Container>
      );

    case VIEWS.UPDATE_PASSWORD:
      return (
        <UpdatePassword
          appearance={appearance}
          supabaseClient={supabaseClient}
          i18n={i18n}
          setMessage={(
            message: { isError: boolean; message: string } | null
          ) => {
            setMessageAndToast(message);
          }}
        />
      );
    case VIEWS.VERIFY_OTP:
      return (
        <VerifyOtp
          appearance={appearance}
          supabaseClient={supabaseClient}
          otpType={otpType}
          i18n={i18n}
          setMessage={(
            message: { isError: boolean; message: string } | null
          ) => {
            setMessageAndToast(message);
          }}
        />
      );
    default:
      return null;
  }
}

// @ts-ignore
Auth.ForgottenPassword = ForgottenPassword;
// @ts-ignore
Auth.UpdatePassword = UpdatePassword;
// @ts-ignore
Auth.MagicLink = MagicLink;
// @ts-ignore
Auth.UserContextProvider = UserContextProvider;
// @ts-ignore
Auth.useUser = useUser;

export default Auth;
