export const black_list = [
  '0cd.cn',
  '15963.fr.nf',
  '1.8259law.com',
  '1xp.fr',
  '4414.cf',
  'aabkhan.fun',
  'ab34.fr',
  'abo-free.fr.nf',
  'ac-cool.c4.fr',
  'wxcv.fr.nf',
  'ac-malin.fr.nf',
  'actarus.infos.st',
  'addedbyjc.0rg.fr',
  'adgloselche.esmtp.biz',
  'adresse.biz.st',
  'adresse.fr.cr',
  'adresse.infos.st',
  'afw.fr.nf',
  'ag.prout.be',
  'a.kwtest.io',
  'aledrioroots.youdontcare.com',
  'aliex.co',
  'alkonealko.cz',
  'alphax.fr.nf',
  'altmailact.tk',
  'altrans.fr.nf',
  'alves.fr.nf',
  'alyxgod.rf.gd',
  'amsspecialist.com',
  'anchrisbaton.acmetoy.com',
  'antispam.fr.nf',
  'ariellemail.com',
  'art.fr.cr',
  'askold.prout.be',
  'assurmail.net',
  'autre.fr.nf',
  'avilana.cf',
  'axaru.com',
  'azab.duckdns.org',
  'aze.kwtest.io',
  'azeqsd.fr.nf',
  'b7s.ru',
  'bahoo.biz.st',
  'ba.prout.be',
  'battery.pw',
  'bboys.fr.nf',
  'b.dynip.org',
  'bibi.biz.st',
  'bin-ich.com',
  'binich.com',
  'bmn.ch.ma',
  'breizh.im',
  'cabiste.fr.nf',
  'calendro.fr.nf',
  'calima.asso.st',
  'carioca.biz.st',
  'carnesa.biz.st',
  'cegetel.fr.nf',
  'c-eric.fr.cr',
  'c-eric.fr.nf',
  'certexx.fr.nf',
  'checkcc.org',
  'cheznico.fr.cr',
  'cli.cloudns.cl',
  'cloud.gaobo.org',
  'clutunpodli.ddns.info',
  'cobal.infos.st',
  'contact.biz.st',
  'contact.infos.st',
  'cookie007.fr.nf',
  'cool.fr.nf',
  'courriel.fr.nf',
  'courrier.fr.cr',
  'cpc.cx',
  'cubox.biz.st',
  'dao.pp.ua',
  'darty.biz.st',
  'darty.fr.cr',
  'dede.infos.st',
  'degap.fr.nf',
  'desfrenes.fr.nf',
  'devony.fun',
  'dgse.infos.st',
  'dioscolwedddas.3-a.net',
  'dis.hopto.org',
  'dispo.sebbcn.net',
  'dlvr.us.to',
  'dmts.fr.nf',
  'dnrcloud.online',
  'doviaso.fr.cr',
  'dreamgreen.fr.nf',
  'dripzgaming.com',
  'd.tcp4.me',
  'e3b.org',
  'ealea.fr.nf',
  'edwardnmkpro.design',
  'e.freeddns.us',
  'elmail.4pu.com',
  'emaildark.fr.nf',
  'email.jjj.ee',
  'emailspot.org',
  'e.ntdll.top',
  'es.prout.be',
  'eureka.0rg.fr',
  'expohdpremium100.hide.biz.st',
  'extanewsmi.zzux.com',
  'facturecolombia.info',
  'fhpfhp.fr.nf',
  'flobo.fr.nf',
  'freemail.biz.st',
  'freemail.fr.cr',
  'frostmail.fr.cr',
  'frostmail.fr.nf',
  'f.tftpd.net',
  'fuckingrockwell.site',
  'fuppurge.info',
  'g.64-b.it',
  'galaxim.fr.nf',
  'gathelabuc.almostmy.com',
  'gggggg.fr.cr',
  'ggmail.biz.st',
  'gimuemoa.fr.nf',
  'gladogmi.fr.nf',
  'gland.xxl.st',
  'globalinternet.fr.nd',
  'gmailcomcn.gq',
  'gmaive.com',
  'goprems.fun',
  'gratis-gratis.com',
  'gratosmail.fr.nf',
  'haben-wir.com',
  'habenwir.com',
  'haihro.cloud',
  'heavenofgaming.com',
  'hide.biz.st',
  'himail.infos.st',
  'hunnur.com',
  'icidroit.info',
  'imails.asso.st',
  'imap.fr.nf',
  'ingrok.win',
  'internaut.us.to',
  'ip11.tk',
  'isep.fr.nf',
  'ist-hier.com',
  'iya.fr.nf',
  'jackymel.xl.cx',
  'javecutie.shop',
  'jeodumifi.ns3.name',
  'jetable.fr.nf',
  'jetable.org',
  'jinva.fr.nf',
  'jmail.fr.nf',
  'kaishime.com',
  'kei-digital.com',
  'kemulastalk.https443.org',
  'kenecrehand.port25.biz',
  'korekgas.info',
  'kurarat.us',
  'kyuusei.fr.nf',
  'lacraffe.fr.nf',
  'lai.adamhayward.co.uk',
  'lai.punked.us',
  'le.monchu.fr',
  'lerch.ovh',
  'leysatuhell.sendsmtp.com',
  'likeageek.fr.nf',
  'louvaug.cf',
  'luvely.fun',
  'mabal.fr.nf',
  'ma-boite-aux-lettres.infos.st',
  'machen-wir.com',
  'ma.ezua.com',
  'mai.25u.com',
  'mai.dhcp.biz',
  'mail34.fr',
  'mailadresi.tk',
  'mailbox.biz.st',
  'mail.hsmw.net',
  'mail.i-dork.com',
  'mail.ip100.tk',
  'mail.jab.fr.cr',
  'mail-mario.fr.nf',
  'mail.myserv.info',
  'mail.n3t.be',
  'mailprohub.com',
  'mailsafe.fr.nf',
  'mailsnails.com',
  'mail.tbr.fr.nf',
  'mail.xstyled.net',
  'mail.yabes.ovh',
  'ma.zyns.com',
  'mcat.uno',
  'mcdomaine.fr.nf',
  'mdstm.cf',
  'mega.zik.dj',
  'mesemails.fr.nf',
  'mess-mails.fr.nf',
  'mickaben.biz.st',
  'mickaben.fr.cr',
  'mickaben.fr.nf',
  'mickaben.xxl.st',
  'miistermail.fr',
  'miloras.fr.nf',
  'moncourriel.fr.nf',
  'moncourrier.fr.nf',
  'mondial.asso.st',
  'monemail.fr.nf',
  'monmail.fr.nf',
  'monsieurbiz.wtf',
  'mortmesttesre.wikaba.com',
  'mottel.fr',
  'mr-email.fr.nf',
  'm.tartinemoi.com',
  'mx.fuppurge.info',
  'mx.job6100.online',
  'mymailbox.xxl.st',
  'mymaildo.kro.kr',
  'mymail.infos.st',
  'mymail.ns01.biz',
  'mynes.com',
  'myself.fr.nf',
  'neatstats.com',
  'new.ovh',
  'nhau.ku4oy.us',
  'nidokela.biz.st',
  'nikora.biz.st',
  'nikora.fr.nf',
  'nishimuramadoka18.afw.fr.nf',
  'nomail.xl.cx',
  'nomes.fr.cr',
  'nomes.fr.nf',
  'noreply.fr',
  'nori24.tv',
  'nospam.fr.nf',
  'nospam.ze.tc',
  'noyp.fr.nf',
  'nrvna.club',
  'nyndt.top',
  'o--b.tk',
  'omicron.token.ro',
  'orange.fr.mu',
  'oyimail.store',
  'pamil.1s.fr',
  'pamil.fr.nf',
  'parleasalwebp.zyns.com',
  'pasdus.fr.cr',
  'patreonic.com',
  'paulprems.cf',
  'pectcandtive.gettrials.com',
  'pelisservispremium.com',
  'pitiful.pp.ua',
  'pitimail.xxl.st',
  'pixelgagnant.net',
  'plixup.com',
  'pliz.fr.nf',
  'pmail.1s.fr',
  'p.mypi.co',
  'pokemons1.fr.nf',
  'popol.fr.nf',
  'posvabotma.x24hr.com',
  'poubelle-du.net',
  'poubelle.fr.nf',
  'prc.cx',
  'prewx.com',
  'prime786.cf',
  'pro001.cf',
  'p.tcp4.me',
  'q0.us.to',
  'qq.darfing.top',
  'quichebedext.freetcp.com',
  'randol.infos.st',
  'rapidefr.fr.nf',
  'r.dynserv.org',
  'readmail.biz.st',
  'red.fr.cr',
  'redi.fr.nf',
  'rygel.infos.st',
  'sabrestlouis.com',
  'sage.yopmail.com',
  'sake.prout.be',
  'sanporeta.ddns.name',
  'saruawaeah.co.uk',
  'sbby.fun',
  'scycirce.cf',
  'sdj.fr.nf',
  'sendos.fr.nf',
  'sendos.infos.st',
  'sind-hier.com',
  'sindhier.com',
  'sind-wir.com',
  'sindwir.com',
  'sirttest.us.to',
  'six25.biz',
  'skynet.infos.st',
  'smile.4nmn.com',
  'smtp.yopmail.com',
  's.mypi.co',
  'soozoop.com',
  'souma.duckdns.org',
  'spacibbacmo.lflink.com',
  'spam.9001.ovh',
  'spam.aleh.de',
  'spam.quillet.eu',
  'speed.1s.fr',
  'ssi-bsn.infos.st',
  'sufffer.ml',
  'suppdiwaren.ddns.me.uk',
  'sutang666.top',
  'svt.run.place',
  'tagara.infos.st',
  't.dynip.org',
  'terre.infos.st',
  'test.actess.fr',
  'tester2341.great-site.net',
  'test.inclick.net',
  'test-infos.fr.nf',
  'testkkk.zik.dj',
  'thug.pw',
  'tiararose.mypi.co',
  'tiscali.fr.cr',
  'tmp2.luca.1337.cx',
  'tmp.qqu.be',
  'tmp.world-of-ysera.com',
  'tmp.x-lab.net',
  'tokai.tk',
  'tokkiland.online',
  'toolbox.ovh',
  'toopitoo.com',
  'torrent411.fr.nf',
  'totococo.fr.nf',
  'tpaglucerne.dnset.com',
  'trash.lapier.re',
  'tw.bbsfy882.ml',
  'tweakacapun.wwwhost.biz',
  'tweet.fr.nf',
  'upc.infos.st',
  'us.prout.be',
  'ves.ink',
  'v.freeddns.us',
  'vigilantkeep.net',
  'vip.222.ac.cn',
  'vip.ep77.com',
  'vode.rwbcode.com',
  'wanadoo.fr.mu',
  'warlus.asso.st',
  'webclub.infos.st',
  'webstore.fr.nf',
  'whatagarbage.com',
  'wir-sind.com',
  'wishy.fr',
  'woofidog.fr.nf',
  'w.ownip.net',
  'www3.freetcp.com',
  'w.x443.pw',
  'wzofit.com',
  'x.dynserv.org',
  'yagersmail.com',
  'yahooz.xxl.st',
  'yaloo.fr.nf',
  'y.dldweb.info',
  'y.iotf.net',
  'y.jerrycraft.tk',
  'y.lochou.fr',
  'ymail.1s.fr',
  'ymail.ploki.fr',
  'ymail.rr.nu',
  'ymail.villien.net',
  'ym.cypi.fr',
  'ym.digi-value.fr',
  'yop.emersion.fr',
  'yop.kd2.org',
  'yop.kyriog.fr',
  'yop.mabox.eu',
  'yopmail.com',
  'yopmail.fr',
  'yopmail.net',
  'yopmail.ozm.fr',
  'yopmail.ploki.fr',
  'yop.mc-fly.be',
  'yop.moolee.net',
  'yop.profmusique.com',
  'yop.smeux.com',
  'yop.too.li',
  'yoptruc.fr.nf',
  'yop.tv-sante.com',
  'yop.uuii.in',
  'yotmail.fr.nf',
  'yourmailtoday.com',
  'ypmail.sehier.fr',
  'ywzmb.top',
  'z.forumz.info',
  'zimel.fr.cr',
  'zinc.fr.nf',
  'zinfighkildo.ftpserver.biz',
  'zorg.fr.nf',
  'zouz.fr.nf',
  'zx81.ovh'
];
