import { Theme } from "@supabase/auth-ui-shared";
// colors: {
//   primary: '#8559F4',
//   secondary: '#F57C8A',
//   background: '#04062A',
//   'gray-900': '#13121D',
//   'gray-800': '#1F1D2B',
//   'gray-700': '#2D2B3D',
//   mendable: {
//     50: '#f5f3ff',
//     100: '#ede9fe',
//     200: '#dcd6fe',
//     300: '#c3b6fc',
//     400: '#a68cf9',
//     500: '#8559f4',
//     600: '#793ceb',
//     700: '#692ad7',
//     800: '#5823b4',
//     900: '#491e94',
//     950: '#2c1164'
//   },
export const ThemeSupa: Theme = {
    default: {
      colors: {
        brand: '#8559F4',
        brandAccent: '#8559F4',
        brandButtonText: 'white',
        defaultButtonBackground: '#1e293b',
        defaultButtonBackgroundHover: '#334155',
        defaultButtonBorder: '#64748b',
        defaultButtonText: 'gray',
        dividerBackground: '#64748b',
        inputBackground: 'transparent',
        inputBorder: '#64748b',
        inputBorderHover: 'gray',
        inputBorderFocus: 'gray',
        inputText: 'black',
        inputLabelText: 'gray',
        inputPlaceholder: 'darkgray',
        messageText: 'gray',
        messageTextDanger: 'red',
        anchorTextColor: 'gray',
        anchorTextHoverColor: 'darkgray',
      },
      space: {
        spaceSmall: '4px',
        spaceMedium: '8px',
        spaceLarge: '16px',
        labelBottomMargin: '8px',
        anchorBottomMargin: '4px',
        emailInputSpacing: '4px',
        socialAuthSpacing: '4px',
        buttonPadding: '10px 15px',
        inputPadding: '10px 15px',
      },
      fontSizes: {
        baseBodySize: '13px',
        baseInputSize: '14px',
        baseLabelSize: '14px',
        baseButtonSize: '14px',
      },
      fonts: {
        bodyFontFamily: `ui-sans-serif, sans-serif`,
        buttonFontFamily: `ui-sans-serif, sans-serif`,
        inputFontFamily: `ui-sans-serif, sans-serif`,
        labelFontFamily: `ui-sans-serif, sans-serif`,
      },
      // fontWeights: {},
      // lineHeights: {},
      // letterSpacings: {},
      // sizes: {},
      borderWidths: {
        buttonBorderWidth: '1px',
        inputBorderWidth: '1px',
      },
      // borderStyles: {},
      radii: {
        borderRadiusButton: '4px',
        buttonBorderRadius: '4px',
        inputBorderRadius: '4px',
      },
      // shadows: {},
      // zIndices: {},
      // transitions: {},
    },
    dark: {
      colors: {
        brandButtonText: 'white',
        defaultButtonBackground: '#2e2e2e',
        defaultButtonBackgroundHover: '#3e3e3e',
        defaultButtonBorder: '#3e3e3e',
        defaultButtonText: 'white',
        dividerBackground: '#2e2e2e',
        inputBackground: '#1e1e1e',
        inputBorder: '#3e3e3e',
        inputBorderHover: 'gray',
        inputBorderFocus: 'gray',
        inputText: 'white',
        inputPlaceholder: 'darkgray',
      },
    },
  }